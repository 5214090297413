import { ROLE } from "constant";
import useSWR from "swr";
import {
  Account,
  AccountWithPaging,
  SiteSummary,
  AlertOverview,
  CIOrgDetail,
} from "types/portfolio";
import {
  CaseAllSummary,
  OverageObject,
  Subscription,
  Competitor,
  AggregatedOverage,
  AggregatedMeetingUsageObject,
  AggregatedActiveUserObject,
  SiteSearchOption,
  ChartDefination,
  AggregatedLegacyMeetingUtilizationObject,
  AggregatedLegacyMeetingUsageObject,
  AggregatedTeamUsageObject,
  CiscoContact,
  Contact,
  SiteDetail,
  AggregatedCallingObject,
  AssignmentHistory,
  ExpandPlanDetail,
  ExpandPlanTemplate,
  ExpandPlanHistory,
  Tag,
  Note,
  RefreshDate,
  RollingUsage,
  CollabMapFile,
  BundleSummary,
  OldAggregatedCallingObject,
  Concierge,
  DetailAccount,
  WebexAppDataUsage,
  AggregatedMeetingsNPSScoreObject,
  MeetingsNPSScoreSites,
  QVR,
  GDPR,
  ExpandPlanType,
  ExpandPlanTypeEnum,
  EmailDetail,
  ModernExperience,
  DeviceOverview,
  DeviceList,
  AdvancedFilterMetricItem,
} from "types/summary";
import { Matadata } from "types/app";
import { ConciergeHistory } from "types/portfolio";
import { HealthScoreType } from "types/home";
import { OptionType } from "components/portfolio/advancedFilter/widgets/HierarchyCheckboxAutocomplete";

export const useAccountSKByKey = (accountKey?: string) =>
  useSWR<number>(
    accountKey
      ? `/api/dataservice/v1/metadata/account/${accountKey}/surrogate-account-id`
      : null
  );

export const useAccountById = (accountId?: string, usingBundle = true) =>
  useSWR<Account>(
    accountId
      ? `/api/dataservice/v1/accounts/${accountId}?includeSites=true&usingBundle=${usingBundle}`
      : null
  );

export const useIsAccountFavorite = (accountKey?: string) =>
  useSWR<boolean>(
    accountKey ? `/api/dataservice/v1/tags/favorite/${accountKey}` : null
  );

export const useTagsByKey = (accountKey?: string) =>
  useSWR<Tag[]>(
    accountKey ? `/api/dataservice/v1/accounts/${accountKey}/tags` : null
  );

/**
 *
 * @param accountKey Account Key
 * @param managementType 0:Cisco, 1:Partner
 */

export const useExpandPlanDetailByKey = (
  accountKey?: string,
  version = "current",
  managementType: ExpandPlanType | null = ExpandPlanTypeEnum.Cisco
) =>
  useSWR<ExpandPlanDetail>(
    accountKey && version && managementType !== null
      ? `/api/dataservice/v1/accounts/${accountKey}/expand-plan/${version}?managementType=${managementType}`
      : null
  );

/**
 *
 * @param accountKey Account Key
 * @param managementType 0:Cisco, 1:Partner
 * @param templateVersionNo 1:Old 2:New
 */

export const useExpandPlanTemplateByKey = (
  accountKey?: string,
  managementType: ExpandPlanType | null = ExpandPlanTypeEnum.Cisco,
  templateVersionNo: string | undefined | null | number = "1"
) =>
  useSWR<ExpandPlanTemplate[]>(
    accountKey && managementType !== null && templateVersionNo
      ? `/api/dataservice/v1/accounts/${accountKey}/expand-plan/templates?managementType=${managementType}&templateVersionNo=${templateVersionNo}`
      : null
  );

/**
 *
 * @param accountKey Account Key
 * @param managementType 0:Cisco, 1:Partner
 */

export const useExpandPlanHistoryByKey = (
  accountKey?: string,
  managementType: ExpandPlanType | null = ExpandPlanTypeEnum.Cisco
) =>
  useSWR<ExpandPlanHistory[]>(
    accountKey && managementType !== null
      ? `/api/dataservice/v1/accounts/${accountKey}/expand-plan?managementType=${managementType}`
      : null
  );

export const useExpandPlanAdoptionById = (accountId?: string) =>
  useSWR<{ [key: string]: string | null }[]>(
    accountId
      ? `/api/csdigital/api/sp/v1/expand-plan/${accountId}/adoptions?managementType=0`
      : null
  );

export const useExpandPlanModernExperienceById = (accountId?: string) =>
  useSWR<Array<null | (ModernExperience & { monthId: string })>>(
    accountId
      ? `/api/csdigital/api/sp/v1/expand-plan/${accountId}/modern-experiences?managementType=0`
      : null
  );

export const useProductsRenewalById = (accountId?: string) =>
  useSWR<{ [key: string]: string | null }>(
    accountId
      ? `/api/dataservice/v1/accounts/${accountId}/products/renewals`
      : null
  );

export const useMetadataByCategory = (category?: string) =>
  useSWR<Matadata[]>(
    category
      ? `/api/dataservice/v1/metadata/lookups?category=${category}`
      : null
  );
export const useTwoLevelByCategory = (category?: string) =>
  useSWR<{ [key: string]: string[] }>(
    category
      ? `/api/dataservice/v1/metadata/activity-types?category=${category}`
      : null
  );

export const useMetadataByKey = (key?: string) =>
  useSWR<{ [key: string]: string[] } | string[]>(
    key ? `/api/dataservice/v1/metadata/${key}` : null
  );

export const useCountryMetadata = () =>
  useSWR<{ code: string; name: string; region: string }[]>(
    `/api/portfolio/metadata/country`
  );

export const useContactById = (accountId?: string) =>
  useSWR<CiscoContact>(
    accountId ? `/api/dataservice/v1/accounts/${accountId}/contacts` : null
  );

export const useAssignmentHistory = (accountId?: string) =>
  useSWR<AssignmentHistory[]>(
    accountId
      ? `/api/csdigital/api/assignment/v1/account/history/${accountId}`
      : null
  );

export const useAccountTeamContactById = (accountId?: string) =>
  useSWR<Contact[]>(
    accountId
      ? `/api/dataservice/v1/accounts/${accountId}/all-contacts?moduleName=ACCOUNT_TEAM`
      : null
  );

export const useEndCustomerContactById = (accountId?: string) =>
  useSWR<Contact[]>(
    accountId
      ? `/api/dataservice/v1/accounts/${accountId}/all-contacts?moduleName=CONTACT_MASTER&sort=createTime:desc&loadBPP=1`
      : null
  );

export const useQVRByAccountKey = (accountKey?: string) =>
  useSWR<QVR[]>(
    accountKey
      ? `/api/dataservice/v1/accounts/${accountKey}/qvrs?sort=createdDate:desc`
      : null
  );

export const useNoteById = (accountId?: string) =>
  useSWR<Note[]>(
    accountId ? `/api/dataservice/v1/accounts/${accountId}/notes` : null
  );

export const useSiteSummaryByAccountId = (accountId?: string) =>
  useSWR<SiteSummary[]>(
    accountId ? `/api/dataservice/v1/accounts/${accountId}/site-summary` : null
  );

export const useSitesByAccountId = (accountId?: string) =>
  useSWR<MeetingsNPSScoreSites[]>(
    accountId ? `/api/dataservice/v1/accounts/${accountId}/sites` : null
  );

export const useOrgsByAccountKey = (accountKey?: string) =>
  useSWR<CIOrgDetail[]>(
    accountKey
      ? `/api/dataservice/v1/accounts/${accountKey}/ci-organizations`
      : null
  );

export const useAccountByQuery = (
  role: ROLE,
  query?: string,
  header?: string,
  addPaging?: boolean
) =>
  useSWR<AccountWithPaging>(
    query
      ? [
          `/api/dataservice/v1/accounts${
            role === ROLE.PARTNER_ROLE ? "/my" : ""
          }?${query}`,
          header,
          addPaging,
        ]
      : null
  );

export const useCaseSummaryById = (accountId?: string, mouth?: string) =>
  useSWR<CaseAllSummary>(
    accountId
      ? `/api/dataservice/v1/accounts/${accountId}/case-summary?months=${
          mouth ?? 12
        }`
      : null
  );

export const useSubscriptionsById = (
  accountId?: string,
  options?: {
    includeInactive?: boolean;
    includeSku?: boolean;
    includeSite?: boolean;
    includeSiteInactive?: boolean;
    includeSkuInactive?: boolean;
    includeRenewal?: boolean;
    includeHealthInfo?: boolean;
    summaryOrder?: boolean;
    usingBundle?: boolean;
  }
) => {
  const params = [];
  const {
    includeInactive,
    includeSite,
    includeSku,
    includeSiteInactive,
    includeSkuInactive,
    includeRenewal,
    includeHealthInfo,
    summaryOrder,
    usingBundle,
  } = options ?? {};
  if (includeInactive) {
    params.push("includeInactive=true");
  }
  if (includeSite) {
    params.push("includeSite=true");
  }
  if (includeSku) {
    params.push("includeSku=true");
  }
  if (includeSiteInactive) {
    params.push("includeSiteInactive=true");
  }
  if (includeSkuInactive) {
    params.push("includeSkuInactive=true");
  }
  if (includeRenewal) {
    params.push("includeRenewal=true");
  }
  if (includeHealthInfo) {
    params.push("includeHealthInfo=true");
  }
  if (summaryOrder) {
    params.push("latestSubscriptionOnly=1");
    params.push("sorts=offerCode:asc,serviceStartDate:asc");
  }
  if (usingBundle) {
    params.push("usingBundle=true");
  }
  return useSWR<Subscription[]>(
    accountId && params.length
      ? `/api/dataservice/v1/accounts/${accountId}/subscriptions?${params.join(
          "&"
        )}`
      : null
  );
};

export const useCompetitorsByKey = (accountKey?: string) =>
  useSWR<Competitor[]>(
    accountKey ? `/api/dataservice/v1/accounts/${accountKey}/competitors` : null
  );

export const useOverageById = (accountId?: string) =>
  useSWR<{ data: OverageObject[]; aggregatedOverage: AggregatedOverage }>(
    accountId ? `/api/summary/usage/overage?accountId=${accountId}` : null
  );

export const useSiteDetailById = (accountId?: string) =>
  useSWR<SiteDetail[]>(
    accountId ? `/api/dataservice/v1/accounts/${accountId}/sites` : null
  );

export const useChartDefinationByModule = (module: string) =>
  useSWR<ChartDefination[]>(
    `/api/dataservice/v1/metadata/comments?module=${module}`
  );

export const useMeetingUsageBySelectedSites = (
  selectedSites: SiteSearchOption[],
  rangeType?: "daily" | "weekly" | "monthly",
  rangeValue?: string
) =>
  useSWR<AggregatedMeetingUsageObject>(
    selectedSites.length
      ? `/api/summary/usage/meeting?hgsSiteId=${selectedSites
          .map((x) => x.value.split("|")?.[1])
          .join(",")}&rangeType=${rangeType ?? "monthly"}&rangeValue=${
          rangeType !== "monthly"
            ? rangeValue
            : rangeValue
            ? +rangeValue > 24
              ? 24
              : rangeValue
            : "13"
        }`
      : null
  );

export const useMeetingsNPSScoreBySelectedSites = (
  hgsSiteId: number | undefined
) =>
  useSWR<AggregatedMeetingsNPSScoreObject>(
    hgsSiteId
      ? `/api/dataservice/v1/sites/product-nps?hgsSiteId=${hgsSiteId}`
      : null
  );

export const useActiveUsersById = (
  accountId: string,
  rangeType: "daily" | "weekly" | "monthly",
  dateRange: number
) =>
  useSWR<AggregatedActiveUserObject>(
    accountId
      ? `/api/summary/usage/teams/active-users?accountId=${accountId}&rangeType=${
          rangeType ?? "monthly"
        }&dateRange=${dateRange ?? 13}`
      : null
  );

export const useTeamUsageById = (accountId: string, dateRange: number) =>
  useSWR<AggregatedTeamUsageObject>(
    accountId
      ? `/api/summary/usage/teams/teams-usage?accountId=${accountId}&dateRange=${
          dateRange ?? 13
        }`
      : null
  );

export const useCallingById = (accountId: string, dateRange: number) =>
  useSWR<OldAggregatedCallingObject>(
    accountId
      ? `/api/summary/usage/calling/old-calling?accountId=${accountId}&months=${
          dateRange ?? 12
        }`
      : null
  );

export const useUtilizationUsageBySelectedSites = (
  selectedSites: SiteSearchOption[],
  deploymentModel: string,
  rangeValue?: string
) =>
  useSWR<AggregatedLegacyMeetingUtilizationObject>(
    selectedSites.length
      ? `/api/summary/usage/meeting/legacy/utilization?siteNames=${selectedSites
          .map((x) => x.siteName)
          .join(",")}&rangeValue=${
          rangeValue ?? "12"
        }&deploymentModel=${deploymentModel}`
      : null
  );

export const useUtilizationUsageBySiteNames = (
  siteNames?: string,
  rangeValue?: string
) =>
  useSWR<AggregatedLegacyMeetingUtilizationObject>(
    siteNames
      ? `/api/summary/usage/meeting/legacy/utilization?siteNames=${siteNames}&rangeValue=${
          rangeValue ?? "12"
        }`
      : null
  );

export const useRollingUsage = (accountKey?: string) =>
  useSWR<RollingUsage>(
    accountKey ? `/api/dataservice/v1/accounts/${accountKey}/utilization` : null
  );

export const useLegacyMeetingUsageBySelectedSites = (
  selectedSites: SiteSearchOption[],
  rangeValue?: string
) =>
  useSWR<AggregatedLegacyMeetingUsageObject>(
    selectedSites.length
      ? `/api/summary/usage/meeting/legacy?siteNames=${selectedSites
          .map((x) => x.siteName)
          .join(",")}&rangeValue=${rangeValue ?? "12"}`
      : null
  );

export const useRefreshDates = () =>
  useSWR<RefreshDate[]>(`/api/dataservice/v1/info/refresh-dates`);

export const useRefreshDate = (query?: string) =>
  useSWR<string>(`/api/dataservice/v1/info/refresh-date${query ?? ""}`);

export const useLatestCollabMap = (accountId: string | undefined) =>
  useSWR<CollabMapFile>(
    accountId
      ? `/api/dataservice/v1/accounts/${accountId}/collab-map/latest`
      : null
  );

export const useAccountSummaryById = (accountId?: string) =>
  useSWR<BundleSummary>(
    accountId
      ? `/api/dataservice/v1/accounts/${accountId}/bundle-skus/summary`
      : null
  );

export const useCallingDataById = (
  accountId: string,
  rangeType: string,
  dateRange: string
) =>
  useSWR<AggregatedCallingObject>(
    accountId
      ? `/api/summary/usage/calling/new-calling?accountId=${accountId}&rangeType=${rangeType}&rangeValue=${dateRange}`
      : null
  );

export const useConciergeById = (
  accountId: string | undefined,
  isPartner = false
) =>
  useSWR<Concierge>(
    accountId && !isPartner
      ? `/api/dataservice/v1/accounts/${accountId}/concierges/latest`
      : null
  );

export const useConciergeHistoryById = (accountId: string | undefined) =>
  useSWR<ConciergeHistory[]>(
    accountId
      ? `/api/dataservice/v1/accounts/${accountId}/concierges/history`
      : null
  );

export const useAccountBySearch = (query?: string) =>
  useSWR<DetailAccount[]>(
    query ? `/api/dataservice/v1/metadata/search?${query}` : null
  );

export const useWebexAppById = (accountId: string) =>
  useSWR<WebexAppDataUsage>(
    accountId
      ? `/api/summary/usage/meeting/webex-app?accountId=${accountId}`
      : null
  );

export const useActiveAlertCount = (accountKey: string | undefined) =>
  useSWR<AlertOverview>(
    accountKey
      ? `/api/dataservice/v1/accounts/${accountKey}/alerts/overview`
      : null
  );

export const useInsightData = (accountKey: string, subKey: string | null) =>
  useSWR<HealthScoreType>(
    accountKey && subKey
      ? `/api/dataservice/v1/accounts/${accountKey}/health-info/${subKey}`
      : null
  );

export const useGDPRInfoByAccountKey = (accountKey: string, months: string) =>
  useSWR<GDPR>(
    accountKey && months
      ? `/api/dataservice/v1/accounts/${accountKey}/gdpr?months=${months}`
      : null
  );

export const useEmailListById = (contactId: string) =>
  useSWR<EmailDetail[]>(
    contactId ? `/api/dataservice/v1/contacts/${contactId}/emails` : null
  );

export const useEmailDetailById = (contactId: string, emailId: string) =>
  useSWR<EmailDetail>(
    contactId && emailId
      ? `/api/dataservice/v1/contacts/${contactId}/emails/${emailId}`
      : null
  );

export const useDeviceOverviewDataById = (account_id: string, dateRange = 6) =>
  useSWR<DeviceOverview>(
    account_id && dateRange
      ? `/api/dataservice/v1/accounts/${account_id}/device-bookings/overview?dateRange=${dateRange}`
      : null
  );
export const useDeviceDataById = (account_id: string, dateRange = 6) =>
  useSWR<DeviceList[]>(
    account_id && dateRange
      ? `/api/dataservice/v1/accounts/${account_id}/device-bookings?dateRange=${dateRange}`
      : null
  );

export const useJiraById = (account_id: string) =>
  useSWR(
    account_id ? `/api/dataservice/v1/accounts/${account_id}/ccse/jiras` : null
  );

export const useEPRevampDataById = (accountId: string) =>
  useSWR(
    accountId
      ? `/api/dataservice/v1/success-plans?accountId=${accountId}`
      : null
  );

export const userAdvancedFilterByPage = (page: string) => {
  return useSWR<AdvancedFilterMetricItem[]>(
    page
      ? `/api/dataservice/v1/metadata/advance-filter-config?page=${page}`
      : null
  );
};

export const userAdvancedFilterOptions = (field: string, page: string) => {
  return useSWR<OptionType[]>(
    field && page
      ? `/api/dataservice/v1/metadata/advance-filter-option/${field}?page=${page}`
      : null
  );
};

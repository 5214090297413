import { AlertIconType } from "components/portfolio/components/alert/alert-icon-type";
import { BppDetail, Site, CIOrgDetail } from "./portfolio";
import { HealthScoreType } from "./home";
import { ACTIVITY_STATUS_CONST } from "constant";

export interface BundleSummary {
  arrCloudCalling: number;
  arrCloudContactCenter: number;
  arrCloudDevice: number;
  arrCloudMeetingsMessaging: number;
  arrCloudCPass: number;
  arrNonCollabSecurity: number;
  arrNonCollabService: number;
  arrNonCollabUndefined: number;
  arrNonRecurringRevenue: number;
  arrOnPremCalling: number;
  arrOnPremContactCenter: number;
  arrOnPremDevice: number;
  arrOnPremMeetingMessaging: number;
  arrOther: number;
  arrRecurringCollab: number;
  arrRecurringOther: number;
  mrrCloudCPass: number;
  mrrCloudCalling: number;
  mrrCloudContactCenter: number;
  mrrCloudDevice: number;
  mrrCloudMeetingsMessaging: number;
  mrrNonCollabSecurity: number;
  mrrNonCollabService: number;
  mrrNonCollabUndefined: number;
  mrrNonRecurringRevenue: number;
  mrrOnPremCalling: number;
  mrrOnPremContactCenter: number;
  mrrOnPremDevice: number;
  mrrOnPremMeetingMessaging: number;
  mrrOther: number;
  mrrRecurringCollab: number;
  mrrRecurringOther: number;
  [key: string]: number;
}

export interface Assessment {
  fiscalMonthId: string | null;
  subscriptionKey: string | null;
  noteId: string | null;
  status: string | null;
  forecasting: number | null;
  accountSK: number | undefined;
  accountId: string;
  accountKey: string;
  accountName: string;
  createdBy: string;
  createdDate: string;
  creatorName: string;
  currentSubOrder: number;
  dataPhaseFlag: number;
  epVersionId: string;
  fiscalMonthName: string;
  forecastTypeCC: number | null;
  forecastTypeCalling: number | null;
  forecastTypeOther: number | null;
  forecastTypeTmsmtg: number | null;
  forecastTypeVD: number | null;
  forecastValueCC: number | null;
  forecastValueCalling: number | null;
  forecastValueOther: number | null;
  forecastValueTmsmtg: number | null;
  forecastValueVD: number | null;
  forecastedInvoiceArrCC: number | null;
  forecastedInvoiceArrCalling: number | null;
  forecastedInvoiceArrOther: number | null;
  forecastedInvoiceArrTmsmtg: number | null;
  forecastedInvoiceArrVD: number | null;
  forecastedRevenueRateCC: number | null;
  forecastedRevenueRateCalling: number | null;
  forecastedRevenueRateOther: number | null;
  forecastedRevenueRateTmsmtg: number | null;
  forecastedRevenueRateVD: number | null;
  forecastingName: string;
  forecastingNote: string;
  invoiceArrCC: number | null;
  invoiceArrCalling: number | null;
  invoiceArrOther: number | null;
  invoiceArrTmsmtg: number | null;
  invoiceArrVD: number | null;
  lastModifiedBy: string;
  lastModifiedDate: string;
  overallRiskAssessment: string;
  renewalFactors: string;
  riskSentimentNote: string;
  rowKey: string;
  subRenewalDate: string;
  subRiskAssessment: string;
  subscriptionId: string;
  subscriptionRefId: string;
  totalSubCount: number;
  editable: boolean;
  modifierName: string;
}

export interface Subscription {
  accountKey: string;
  arComments: string | null;
  arDate: string | null;
  arExpectedLossDate: string | null;
  arFlag: string | null;
  arReasonCode: string | null;
  arr: number;
  arrLocal: number;
  arrMrrBundleSummary: BundleSummary;
  callingInvoiceArr: number;
  cancelEffectiveDate: string | null;
  ccwOrderId: string | null;
  channel: string | null;
  ciOrgId: string | null;
  currencyCode: string | null;
  currentPrepaymentTerm: 1;
  effectiveFrom: string | null;
  effectiveTo: string | null;
  initialPrepaymentTerm: number;
  initialTerm: number;
  invoiceArr: number;
  invoiceArrLocal: number;
  invoiceMrr: number;
  invoiceMrrLocal: number;
  knowledgeWorkerCount: number;
  meetingTeamInvoiceArr: number;
  mrr: number;
  mrrLocal: number;
  offerCode: string | null;
  offerName: string | null;
  offerType: string | null;
  offerDescription: string | null;
  orderSellType: null;
  orgUnitId: string | null;
  pcExpectedLossDate: null;
  pcFlag: string | null;
  pcReasonCode: null;
  pcReasonComments: null;
  pcStatus: null;
  purchasedQuantity: number;
  remainingTerm: number;
  renewalDate: string | null;
  renewalTerm: number;
  reseller: string | null;
  resellerId: string | number | null;
  serviceModel: string | null;
  serviceStartDate: string | null;
  siteDetails: SiteDetail[] | null;
  skuCount: number;
  skuDetails: Sku[];
  sparkOfferTypeDetail: string | null;
  status: string | null;
  subscriptionCode: string | null;
  subscriptionId: string | null;
  subscriptionKey: string | null;
  assessment: Assessment;
  healthInfo: HealthScoreType;
  ciOrgs: CIOrgDetail[];
  assessmentAlert: number;
}

export interface Competitor {
  accountKey: string;
  active: string | null;
  comment: string | null;
  competitorName: string | null;
  discoveredBy: string | null;
  discoveredDate: string | null;
  discoveryMethod: string | null;
  siteName: string | null;
}

export interface MeetingsNPSScore {
  time: string;
  data: {
    totalRespondents: number | null;
    totalPromoters: number | null;
    totalPassives: number | null;
    totalDetractors: number | null;
    totalNPSScore: number | null;
    webexAppNPSScore: number | null;
  };
}

export interface MeetingsNPSScoreSites {
  hgsSiteId: number;
  siteUrl: string;
  primarySite: boolean;
  active: boolean;
}

export interface MeetingsNPSScoreSitesOptions {
  name: string;
  siteName: string;
  hgsSiteId: number;
}

export interface Note {
  accountKey: string;
  accountName: string | null;
  /**
   * 0: Public
   * 2: Private
   */
  confidential: number;
  createdBy: string | null;
  createdDate: string | null;
  creatorName: string | null;
  id: string | null;
  lastModifiedBy: string | null;
  lastModifiedDate: string | null;
  modifierName: string | null;
  noteType: string | null;
  notes: string | null;
  type: string | null;
  forecastedInvoiceArrCalling: null | number;
  forecastedInvoiceArrMeeting: null | number;
  forecastedRevenueRateCalling: null | number;
  forecastedRevenueRateMeeting: null | number;
  subscriptionRefId: null | string;
  subRenewalDate: null | string;
  renewalAtRisk: null | string;
  overallRiskAssessment: null | string;
  forecasting: null | string;
  /**
   * -1:Delete
   *  1:Active
   *  0:Inactive
   */
  status: number;
  files?: NoteFile[];
  fiscalMonthId: string | null;
}

export interface NoteFile {
  id: string;
  fileId: string;
  fileName: string;
  fileSize: number | null;
  createdBy: string;
  uploadedBy: string;
  uploadedTime: string;
}

export interface UserInfo {
  name: string;
  email: string;
}

export interface CiscoContact {
  accountKey: string;
  ciscoCsmManager: string;
  ciscoCsmName: string;
  dsm: string;
  escalationEngineer: string;
  partnerCsmName: string;
  partnerGroup: string;
  partnerName: string;
  psmManager: string;
  psmName: string;
  salesOwner: string;
  ccseName: string;
  ccseManagerName: string;
  callingPsmName: string;
  callingEpsmName: string;
  callingPsmManagerName: string;
  callingEpsmManagerName: string;
  ccCsmName: string;
  cxCseName: string;
  ciscoCsmEmail: string;
  partnerCsmEmail: string;
  ciscoCsmManagerEmail: string;
  psmManagerEmail: string;
  ccPartnerCsmName: string;
  ccPartnerSponsorName: string;
  dsmInfo: [];
  ccseInfo: [];
  ccseManagerInfo: [];
  callingEpsmInfo: [];
  callingEpsmManagerInfo: [];
  callingPsmInfo: [];
  callingPsmManagerInfo: [];
  ccCsmInfo: [];
  cxCseInfo: [];
  ccPartnerCsmInfo: [];
  ccPartnerSponsorInfo: [];
  evCsmInfo: UserInfo[];
  evCsmManagerInfo: UserInfo[];
}

export interface AssignmentHistory {
  accountId: string;
  assignedTo: string;
  assigneeType: "Partner CSM" | "Cisco Collab CSM";
  lastModifiedBy: string;
  lastModifiedDate: string;
  status: "Completed";
  assignedToName: string;
  assignedToEmail: string;
}

export interface Contact {
  accountKey: string;
  activityLevel: string;
  chRole: string;
  contactKey: string;
  contactCreateTime: string;
  contactModifiedBy: string;
  contactModifiedTime: string;
  countryCode: string;
  department: string;
  email: string;
  firstName: string;
  jobTitle: string;
  lastLoginDate: string;
  lastName: string;
  lastOptedOutDate: string;
  linkedinProfile: string;
  moduleName: string;
  optOutFlag: "Y" | "N";
  phoneNumber: string;
  preferredLanguage: string;
  roleName: string;
  spUiInputFlag: "Y" | "N";
  ssCode: string;
  /**
   * 0 - Inactive, 1 - Active
   */
  status: 0 | 1;
  tags: string;
  primaryRoles: string;
  id: string;
  distributed: any;
  bpp: number;
  bppDetails: BppDetail[];
  emailCount: number;
  fullName: string;
}

export interface QVR {
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  id: string;
  accountKey: string;
  fiscalQuarter: string;
  audienceIds: string[];
  qvrRole: string;
  painPoints: string[];
  actionItem: string;
  otherNote: string;
  attachmentIds: string[];
  nextScheduledDate: string;
  creatorName: string;
  modifierName: string;
  audiences: Contact[];
  attachments: NoteFile[];
  distributed: boolean;
}

export interface Sku {
  accountId: string | null;
  accountKey: string | null;
  accountName: string | null;
  active: true;
  arr: number;
  arrLocal: number;
  billableFlag: string | null;
  channel: string | null;
  contractId: string | null;
  currencyCode: string | null;
  effectiveFrom: string | null;
  effectiveTo: string | null;
  invoiceArr: number;
  invoiceArrLocal: number;
  invoiceMrr: number;
  invoiceMrrLocal: number;
  mrr: number;
  mrrLocal: number;
  offerCode: string | null;
  offerName: string | null;
  quantity: number;
  serviceStartDate: string | null;
  skuDescription: string | null;
  skuId: string | null;
  skuType: string | null;
  subscriptionId: string | null;
  subscriptionKey: string | null;
  unitPrice: number;
  childDetails: Sku[] | null;
  recurringFlag: string;
  mrrNonCollab: number;
  mrrNonRecurring: number;
  mrrTotalRevenue: number;
  mrrCollab: number;
  mrrOnPrem: number;
  mrrCloud: number;
  attributeSkuId: string | null;
}

export interface Case {
  id: number;
  accountId: string;
  accountKey: string;
  accountName: string;
  summary: string;
  createdDate: string;
  closedDate: string;
  transactionDate: string;
  status: string;
  problemCode: string;
  resolutionCode: string;
  initialSeverity: string;
  currentSeverity: string;
  finalTech: string;
  finalSubTech: string;
}

export interface CaseMonthlySummary {
  time: string;
  data: {
    active: number;
    closed: number;
    created: number;
    webexMeetings: number;
    webexTeams: number;
  } | null;
}

export interface CaseAllSummary {
  created: number;
  active: number;
  closed: number;
  severity1: number;
  refreshedDate: string;
  monthlySummary: CaseMonthlySummary[];
}

export interface TicketDetail {
  accountId: string;
  accountKey: string;
  accountName: string;
  closedDate: string | null;
  createdDate: string;
  currentSeverity: string;
  finalSubTech: string;
  finalTech: string;
  id: number | null;
  initialSeverity: string;
  problemCode: string;
  resolutionCode: string;
  status: string;
  summary: string;
  transactionDate: string;
}

export type OverageCategory = "Storage" | "PeakAttendee" | "Ports" | "Duration";

export interface OverageBreakDown {
  overageCategory: OverageCategory;
  Overage: OverageData[];
}

export interface OverageData {
  year: number;
  month: string;
  value: number;
}
export interface OverageObject {
  subscriptionId: string;
  overage: {
    processResult: string;
    usageReport: {
      graphDetails: {
        mrc: OverageData[];
        overage: OverageData[];
        overageBreakdown: OverageBreakDown[];
      };
    };
  };
}

export type AggregatedOverageType =
  | "storage"
  | "peakAttendee"
  | "ports"
  | "duration"
  | "total";
export interface AggregatedOverage {
  mrc: number[];
  overage: number[];
  storage: number[];
  peakAttendee: number[];
  ports: number[];
  duration: number[];
  categories: string[];
}

export interface MeetingUsageObject {
  meetingUsage: MeetingUsage[];
  siteUsageMaxDate: string;
}

export interface WebexAppDetail {
  platform: string;
  version: string;
  activeUser: number;
  activeUserOnPlatform: number;
  isLatestVersion: boolean;
  isOldestVersion: boolean;
}

export interface WebexAppData {
  activeUserOnLatestVersion: number;
  activeUserOnOldestVersion: number;
  activeUser: number;
  details: WebexAppDetail[];
}

export interface WebexApp {
  data: WebexAppData;
  time: string;
}
export interface WebexAppUsageObject {
  refreshDate?: string;
  summary?: WebexApp[];
}

export interface AggregatedWebexAppUsageData {
  total: number[];
  oldestTotal: number[];
  latestTotal: any;
  oldestVersion: WebexAppDetail[][];
  latestVersion: WebexAppDetail[][];
}

export interface WebexAppDataUsage {
  categories: string[];
  siteUsageMaxDate: string | null;
  data: AggregatedWebexAppUsageData;
}

export interface LegacyMeetingUsageObject {
  meetingUsage: LegacyMeetingUsage[];
  siteUsageMaxDate: string;
}

export interface LegacyMeetingUtilizationObject {
  meetingUsage: LegacyMeetingUtilization[];
  siteUsageMaxDate: string;
}

export type MeetingUsage = BaseMeetingUsage<MeetingUsageData>;
export type LegacyMeetingUsage = BaseMeetingUsage<LegacyMeetingUsageData>;
export type LegacyMeetingUtilization = BaseMeetingUsage<
  LegacyMeetingUtilizationData
>;
interface BaseMeetingUsage<T> {
  data: T | null;
  time: string;
}

export interface MeetingUsageData {
  activeHosts: number;
  mrRecording: number;
  mwssSharing: number;
  pjbrAPJC: number;
  pjbrEMEA: number;
  pjbrLatinAmerica: number;
  pjbrNorthAmerica: number;
  pjbrTotal: number;
  recordingMeetings: number;
  sharingMeetings: number;
  tamAudio: number;
  tamCCAIn: number;
  tamCCAOut: number;
  tamEdgeAudio: number;
  tamFallBack: number;
  tamPSTNIn: number;
  tamPSTNOut: number;
  tamVOIP: number;
  totalMeetings: number;
  totalMeetingsEventsCenter: number;
  totalMeetingsMeetingsCenter: number;
  totalMeetingsMinutesMeetingMinutes: number;
  totalMeetingsOtherCenter: number;
  totalMeetingsSupportCenter: number;
  totalMeetingsTrainingCenter: number;
  totalParticipantsTotalParticipants: number;
  totalParticipantsVideoParticipants: number;
  totalPeopleMinutes: number;
  totalPeopleMinutesEventCenter: number;
  totalPeopleMinutesMeetingCenter: number;
  totalPeopleMinutesOther: number;
  totalPeopleMinutesSupportCenter: number;
  totalPeopleMinutesTrainingCenter: number;
  totalPeopleMinutesVideoMinutes: number;
  totalPeopleVideoMinutesEventCenter: number;
  totalPeopleVideoMinutesSupportCenter: number;
  totalPeopleVideoMinutesOther: number;
  totalPeopleVideoMinutesTrainingCenter: number;
  totalPeopleVideoMinutesMeetingCenter: number;
  totalVideoMeetingsVideoMeetings: number;
  pjbMeetingsApp: number;
  pjbMobileMeetingsApp: number;
  pjbCloudVideoDevice: number;
  pjbOnPremVideoDevice: number;
  pjbWebApp: number;
  pjbWebexApp: number;
  pjbMobileWebexApp: number;
  pjbVDI: number;
  totalMeetingsMinutesVideoMinutes: number;
  totalMeetingsWithWebexAssistant: number;
  totalMeetingsWithBreakoutRoom: number;
  pjbUnknown: number;
  pjbrUnknown: number;
  totalMeetingsWebinar: number;
  totalMeetingsWebcast: number;
}

export interface LegacyMeetingUsageData {
  countSize0_5: number;
  countSize5_10: number;
  countSize10_25: number;
  countSize25more: number;
  countDuration0_30: number;
  countDuration30_60: number;
  countDuration60_120: number;
  countDuration120more: number;
  countAttendanceWindows: number;
  countAttendanceLinux: number;
  countAttendanceMac: number;
  countAttendanceMobile: number;
  countAttendanceOther: number;
  countMobileAndroid: number;
  countMobileBlackBerry: number;
  countMobileOther: number;
  countMobileIphone: number;
  countMobileWinMobile: number;
  totalMeetingsEC: number;
  totalMeetingsSC: number;
  totalMeetingsTC: number;
  totalMeetingsMC: number;
  totalMinutesEC: number;
  totalMinutesSC: number;
  totalMinutesTC: number;
  totalMinutesMC: number;
  totalMinutesVoip: number;
  totalMinutesCallIn: number;
  totalMinutesCallBack: number;
  totalMinutesCallBackIntl: number;
  totalMinutesCallInTollFree: number;
  totalMinutesCallInTollFreeDid: number;
  totalMinutes: number;
  totalMeetings: number;
  totalAudioMeetings: number;
  totalMeetingsVoip: number;
  totalMinutesMPV: number;
  totalMinutesTelep: number;
  totalMinutesTandbergTelep: number;
  totalMeetingsMPV: number;
  totalMeetingsTelep: number;
  totalMeetingsTandbergTelep: number;
  totalMeetingsVideo: number;
  totalMeetingsSharing: number;
  totalMeetingsOneClick: number;
  totalMeetingsOutlook: number;
  totalMeetingsIM: number;
  totalMeetingsOffice: number;
  totalMeetingsWebsite: number;
  totalMeetingsOtherPT: number;
  totalRecordings: number;
  totalPlaybacks: number;
  totalNBRStorageSize: number;
  totalMeetingsCMR: number;
  totalPowerUsersCMR: number;
  totalMeetingsPersonalRoom: number;
  totalMeetingsOther: number;
  totalMeetingsAnywhere: number;
  totalMinutesOther: number;
  totalMinutesAnywhere: number;
  totalAudioMinutes: number;
  totalMinutesVideo: number;
  totalMinutesWebinars: number;
  totalMinutesWebcast: number;
  totalSlidoMeetings: number;
  totalMeetingsWebinars: number;
  totalMeetingsWebcast: number;
  totalSlidoMeetingsWebinars: number;
  totalSlidoMeetingsWebcast: number;
  totalSlidoMeetingsMC: number;
  totalSlidoMeetingsEC: number;
  totalSlidoMeetingsSC: number;
  totalSlidoMeetingsTC: number;
  totalAttendees: number;
  totalVirtualBackgroundAttendeesVID: number;
  totalVirtualBackgroundAttendeesIMG: number;
  totalVirtualBackgroundAttendeesBlur: number;
  totalBackgroundAttendeesNC: number;
  totalVirtualBackground: number;
}

export interface LegacyMeetingUtilizationData {
  totalUtilization: number;
  totalPeakNumbers: number;
  totalLicenses: number;
  totalRegHosts: number;
  totalActiveHosts: number;
  totalPowerHosts: number;
}

export type AggregatedMeetingUsageData = Record<
  keyof MeetingUsageData,
  number[]
>;

export type AggregatedLegacyMeetingUsageData = Record<
  keyof LegacyMeetingUsageData,
  number[]
>;

export type AggregatedLegacyMeetingUtilizationData = Record<
  keyof LegacyMeetingUtilizationData,
  number[]
>;

export type AggregatedLegacyMeetingUsageObject = {
  data: AggregatedLegacyMeetingUsageData;
  siteUsageMaxDate: string | null;
  categories: string[];
};

export type AggregatedMeetingUsageObject = {
  data: AggregatedMeetingUsageData;
  refreshDates?: RefreshDate[];
  categories: string[];
  siteUsageMaxDate: string | null;
};

export type AggregatedMeetingsNPSScoreObject = {
  refreshedDate: string;
  summary: MeetingsNPSScore[];
};

export type AggregatedLegacyMeetingUtilizationObject = {
  data: AggregatedLegacyMeetingUtilizationData;
  siteUsageMaxDate: string | null;
  categories: string[];
};

export interface RollingUsage {
  totalActiveHosts: number | null;
  totalPurchasedLicenses: number | null;
  totalRegisteredHosts: number | null;
  percentActiveHostRegisteredHost: number | null;
  percentActiveHostLicense: number | null;
  meetingNPSScore: number | null;
}

export interface SiteSearchOption {
  hgsSiteId?: number;
  name: string;
  value: string;
  siteName: string;
  subText: string;
  group: string;
  deploymentModel: string;
  clusterCode: string | null;
  mappingValid?: boolean;
  fedRAMPFlag?: string;
}

export interface ChartDefination {
  createdBy: string;
  createdDate: string;
  description: string;
  id: string;
  label: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  module: "meeting_usage";
  type: "definition" | "example";
}

export interface MeetingHost {
  accountName: string;
  cmrCount: number;
  firstName: string;
  hostEmail: string;
  id: string;
  lastName: string;
  lastRefreshDate: string;
  maxAttendee: number;
  maxMeetingAge: number;
  maxMeetingDate: string;
  meetingType: string;
  mobileCount: number;
  mobilePercent: number;
  numByProductTools: number;
  numByProductToolsPercent: number;
  pmrCount: number;
  siteName: string | null;
  totalMeetings: number;
  videoCount: number;
  videoPercent: number;
  voipCount: number;
  voipPercent: number;
  distributed: boolean;
}
export interface ActiveUserObject {
  teamsUsage: ActiveUser[];
  maxDate: string;
}

export interface ActiveUser {
  data: ActiveUserData | null;
  time: string;
}

export interface ActiveUserData {
  // accountKey?: string;
  // subscriptionId?: string;
  // time?: string;
  avgCompanyLoc: number | null;
  avgCompanySize: number | null;
  avgUnqUsers: number | null;
  companyLocation?: string;
  companySize?: string;
  totalActiveUsers: number | null;
  totalEntitlements: number | null;
  // totalPlacedCalls: number | null;
  // totalReadMessage: number | null;
  // totalSentMessages: number | null;
  // totalSharedFiles: number | null;
  csmTheaterSegment: string;
}

export type AggregatedActiveUserData = Record<
  keyof ActiveUserData,
  number[] | string[]
>;

export type AggregatedActiveUserObject = {
  data: AggregatedActiveUserData;
  maxDate: string | null;
  categories: string[];
};

export interface TeamUsageObject {
  teamsUsage: TeamUsage[];
  maxDate: string;
}

export interface TeamUsage {
  data: TeamUsageData | null;
  time: string;
}

export interface TeamUsageData {
  // accountKey?: string;
  // subscriptionId?: string;
  // time?: string;
  // totalActiveUsers: number | null;
  // avgUnqUsers: number | null;
  // totalEntitlements: number | null;
  totalReadMessages: number | null;
  totalSentMessages: number | null;
  totalSharedFiles: number | null;
  totalPlacedCalls: number | null;
  // companyLocation: string;
  // companySize: string;
  // avgCompanySize: number | null;
  // avgCompanyLoc: number | null;
  // csmTheaterSegment: string;
}

export type AggregatedTeamUsageData = Record<
  keyof TeamUsageData,
  number[] | string[]
>;

export type AggregatedTeamUsageObject = {
  data: AggregatedTeamUsageData;
  maxDate: string | null;
  categories: string[];
};
export interface ModernExperience {
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  id: string;
  monthId: string;
  hgsSiteId: number;
  engSiteId: number;
  siteName: string;
  siteUrl: string | null;
  clusterName: string;
  clientVersion: string;
  clusterCode: string;
  clusterDataCenter: string;
  releaseVersion: string;
  pageVersion: string;
  lockDownFlag: "NO" | "YES";
  siteCreatedDate: string;
  lastVersionUpdatedDate: string;
  durationWithLastVersion: number;
  audioType: string;
  ccaAudioType: null | string;
  integratedAudioFlag: "Y" | "N";
  cmr4EnableFlag: "Y" | "N";
  cmr4EnableDate: null | string;
  durationCmr4Enabled: null | string;
  durationCmr4NotEnabled: null | string;
  ciLinkedFlag: null | "Y" | "N";
  ciLinkedDate: null | string;
  durationCiLinked: null | string;
  durationCiNotLinked: null | string;
  ciEnabledFlag: "Y" | "N";
  ciEnabledDate: string;
  durationCiEnabled: number;
  durationCiNotEnabled: null | string;
  chEnabledFlag: "Y" | "N";
  chEnabledDate: string;
  durationChEnabled: number;
  durationChNotEnabled: null | string;
  ssoEnabledSiteAdmin: "Y" | "N";
  proximityFlag: "Y" | "N";
  desktopAppFlag: "Y" | "N";
  desktopAppVersion: number;
  desktopAppEnabledDate: number;
  durationDesktopAppEnabled: number;
  enabledGdm: "Y" | "N";
  peopleInsightEnabledFlag: "Y" | "N";
  isDirSyncEnabled: "Y" | "N";
  verifiedDomain: "Y" | "N";
  hybCalExch: "Y" | "N";
  hybCalO365: "Y" | "N";
  hybCalGg: "Y" | "N";
  hybCall: "Y" | "N";
  hybMessage: "Y" | "N";
  hybVideoMesh: "Y" | "N";
  hybServiceability: "Y" | "N";
  convertedUserFlag: null | "Y" | "N";
  usersRemainSiteAdmin: null | number;
  usersInCh: null | number;
  ciOrgId: "771f60e5-0f2a-4770-b5e7-e724f8df5bbd";
  onlineDeviceFlag: "Y" | "N";
  ssoEnabledCH: "Y" | "N";
  nonAdminUser: "Y" | "N";
  webexAssistant: number;
  autoAssignLicense: "Y" | "N";
  potentialNewUserFlag: "Y" | "N";
  preferredLanguage: "EN-US";
  accountId: "1001720707-1018133507";
  accountName: "BURLINGTON COAT FACTORY OF NEW JERSEY LLC";
  teleDomain: "GIVR_taatdus-+1ttf+1";
  tenantName: "Annuity";
  tspFlag: "Y" | "N";
  zoomFlag: "Y" | "N";
  activeHostPast30Days: number;
  meetingHostedPast30Days: number;
  meetingDurationPast30Days: number;
  peopleMinutesPast30Days: number;
  activeHostPast90Days: number;
  meetingHostedPast90Days: number;
  meetingDurationPast90Days: number;
  peopleMinutesPast90Days: number;
  registeredHostCount: number;
  status: "ACTIVE" | "INACTIVE";
  ssoEnabled: boolean;
  hybridCalendarEnabled: boolean;
  siteManagement: "Site Admin managed" | "Control Hub managed";
  videoPlatformVersion: number;
  inSiteAdmin: "Y" | "N";
  hasClaimedDomain: "Y" | "N";
}

export type SiteDetail = ModernExperience & Site;
export interface Calling {
  data: CallingData | null;
  time: string;
}
export interface CallingObject {
  summary: Calling[];
  refreshedDate: string;
}
export interface CallingData {
  totalCallingOther: number | null;
  totalCallingDevice: number | null;
  totalCallingMobile: number | null;
  totalCallingWeb: number | null;
  totalCallingDeskPhone: number | null;
  totalCallingDesktop: number | null;
  totalMinutesOther: number | null;
  totalMinutesDevice: number | null;
  totalMinutesMobile: number | null;
  totalMinutesWeb: number | null;
  totalMinutesDeskPhone: number | null;
  totalMinutesDesktop: number | null;
  totalMinutes: number | null;
  totalCallings: number | null;
}

export type AggregatedCallingData = Record<keyof CallingData, number[]>;

export type AggregatedCallingObject = {
  data: AggregatedCallingData;
  maxDate: string | null;
  categories: string[];
};
//
export interface OldCalling {
  data: OldCallingData | null;
  time: string;
}
export interface OldCallingObject {
  summary: OldCalling[];
  refreshedDate: string;
}
export interface OldCallingData {
  deskphone: number | null;
  mobile: number | null;
  video: number | null;
  desktopClient: number | null;
  other: number | null;
  total: number | null;
}

export type OldAggregatedCallingData = Record<keyof OldCallingData, number[]>;

export type OldAggregatedCallingObject = {
  data: OldAggregatedCallingData;
  maxDate: string | null;
  categories: string[];
};
//

export const ExpandPlanStatusEnum = {
  "Work-In-Progress": 0,
  Completed: 1,
} as const;

export type ExpandPlanStatus = typeof ExpandPlanStatusEnum[keyof typeof ExpandPlanStatusEnum];

export const ExpandPlanTypeEnum = {
  Cisco: 0,
  Partner: 1,
} as const;

export type ExpandPlanType = typeof ExpandPlanTypeEnum[keyof typeof ExpandPlanTypeEnum];

export interface ExpandPlanHistory {
  accountKey: string;
  createUser: string;
  createdBy: string;
  createdDate: string;
  id: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  managementType: ExpandPlanType;
  modifiedUser: string;
  status: ExpandPlanStatus;
  userId: string;
  versionName: string;
  versionNo: number;
  value: string;
}
export interface ExpandPlanDetail {
  version: {
    accountKey: string;
    createUser: string | null;
    createdBy: string | null;
    createdDate: string | null;
    id: string | null;
    lastModifiedBy: string | null;
    lastModifiedDate: string | null;
    modifiedUser: string | null;
    status: ExpandPlanStatus;
    userId: string | null;
    versionName: string | null;
    versionNo: number | null;
    managementType: ExpandPlanType;
    templateVersionNo: number;
  };
  data: {
    attrSeq: number;
    attributeDisplayName: string;
    attributeName: string;
    categoryDisplayName: string;
    categoryName: string;
    categorySeq: number;
    templateId: string;
    value: null | string;
    histories: ExpandPlanHistory[];
  }[];
  otherSnapshots: any;
}

export interface ExpandPlanTemplateAttribute {
  active: boolean;
  description: string | null;
  displayName: string;
  name: string;
  required: boolean;
  type:
    | "textbox"
    | "dropdownlist"
    | "textArea"
    | "label"
    | "table"
    | "multipleDropdownList";
  valueSize: number;
  lookupCategory?: string;
  templateId: string;
  sequence: number;
  condition: string | null;
  exportStr: string | null;
}

export interface ExpandPlanTemplateCategory {
  displayName: string;
  name: string | null;
  sequence: number;
  attributes: ExpandPlanTemplateAttribute[];
  description?: string;
}

export interface ExpandPlanTemplate {
  categories: ExpandPlanTemplateCategory[];
}

export interface Tag {
  id: string;
  accountKey: string;
  name: string;
  /**
   * 1 - System, 2 - Personal
   */
  type: 1 | 2;
  description: null | string;
}

export interface RefreshDate {
  componentName: string;
  lastRefreshDate: string;
  moduleName: string;
}

export interface ForecastParam {
  noteId: string | null;
  subId: string | null;
  fiscalId: string | null;
  showForecast: boolean;
  status: string | null;
  accountKey: string | undefined;
  noteStatus: string;
  // accountSK: number | undefined;
}

export interface CollabMapFile {
  creatorName: string;
  edcsId: string;
  fileName: string;
  fileSize: number;
  id: string;
  mimeType: string;
  nodeRef: string;
  refId: string;
  status: number;
  versionRef: string;
  createdBy: string;
  createdDate: string;
}

export interface Concierge {
  accountStatus: string;
  status: string;
  funded: string;
  effectiveFrom: string;
  effectiveTo: string;
  createdDate: string;
  createdBy: string;
  creatorName: string;
  accountKey: string;
  id: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  modifierName: string;
}

export type MatchedType = "EQUALS" | "CONTAINS" | "STARTS";

export interface MatchedItems {
  matchedField: string;
  matchedValue: string;
  matchedValueStatus: boolean;
  matchedType: MatchedType;
}
export interface DetailAccount {
  accountId: string;
  accountKey: string;
  accountName: string;
  accountStatus: string;
  confidential: boolean;
  assignedOrTagged: boolean;
  items: MatchedItems[];
}

export interface AlertAccount {
  id: string;
  category: string;
  type: AlertIconType;
  accountKey: string;
  accountId: string;
  accountName: string;
  referenceId: string;
  name: string;
  description: string;
  swssAccountTypeIndicator: null;
  status: string;
  noteId: string;
}

export interface ActivityAccount {
  id: string;
  accountKey: string;
  accountId: string;
  accountName: string;
  activityName: string;
  activityStatus: typeof ACTIVITY_STATUS_CONST[keyof typeof ACTIVITY_STATUS_CONST];
  type: string;
  subType: string;
  priority: string;
  dueDate: string;
  overdue: boolean;
  allTasks: number;
  closedTasks: number;
  overdueTasks: number;
  closedDate: string;
  assigneeId: string;
  assigneeName: string;
  assigneeRole: string;
  createdDate: string;
  createdBy: string;
  creatorName: string;
  creatorRole: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  modifierName: string;
  modifierRole: string;
  newActivity: boolean;
  editable: boolean;
  deletable: boolean;
  invoiceArr: number;
  products: string[];
  priorityIndicatorRank: number;
  purpose: string;
  tasks: ActivityTask[];
}

export interface ActivityGroupResponse {
  count: number;
  filter: { field: string; operator: string; value: string };
  groupName: string;
}

export interface Counts {
  total: number;
  createdByMe: number;
  assignedToMe: number;
  assignedToMyTeam: number;
  open: number;
  closedCompleted: number;
  closedNoAction: number;
  overdue: number;
  overdueTasks: number;
  top150: number;
  top500: number;
  notStarted: number;
  inProgress: number;
  blocked: number;
}

export interface ActivityDetail {
  id: string;
  accountKey: string;
  accountId: string;
  accountName: string;
  activityName: string;
  type: string;
  subType: string;
  activityStatus: typeof ACTIVITY_STATUS_CONST[keyof typeof ACTIVITY_STATUS_CONST];
  activityDescription: string;
  priority: string;
  dueDate: string;
  overdue: boolean;
  allTasks: number;
  openTasks: number;
  overdueTasks: number;
  closedTasks: number;
  closedDate: string;
  assigneeId: string;
  assigneeName: string;
  assigneeRole: string;
  createdDate: string;
  createdBy: string;
  creatorName: string;
  creatorRole: string;
  modifierName: string;
  modifierRole: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  newActivity: boolean;
  editable: boolean;
  deletable: boolean;
  invoiceArr: number;
  attachments: NoteFile[];
  product: string;
  products: string[];
  description: string;
  purpose: string;
  systemRelated: boolean;
  category: "SYSTEM" | "PROGRAM" | "PERSONAL";
  tasks?: ActivityTask[];
  customFields?: string;
  playbookId: string;
  accountStatus?: string;
}

export interface ActivityTask {
  activityId: string;
  attachments: NoteFile[];
  attachmentIds: string[];
  closed: boolean;
  closedDate: string;
  createdBy: string;
  creatorName: string;
  createdDate: string;
  deletable: boolean;
  dueDate: string;
  editable: boolean;
  lastModifiedBy: string;
  lastModifiedDate: string;
  modifierName: string;
  overdue: boolean;
  taskDescription: string;
  taskId: string;
  taskName: string;
  taskNote: string | null;
  taskStatus: typeof ACTIVITY_STATUS_CONST[keyof typeof ACTIVITY_STATUS_CONST];
  systemRelated: boolean;
  system: boolean;
  taskNoteChanged: false;
  taskNoteModifiedBy: string;
  taskNoteModifiedDate: string;
  taskNoteModifierName: string;
  taskCustomFields: string;
}

export interface Assignee {
  name: string;
  role: string;
  cecId: string;
}

export interface ActivityType {
  Communication: string[];
  Meeting: string[];
  Campaign: string[];
  "Account Assessments": string[];
  Other: [];
}

export interface AssigneeType {
  name: string;
  cecId: string;
  role: string;
}

export interface Comment {
  refId: string;
  id: string;
  type: "ACTIVITY";
  // 1 - Created; 2 - Edited ; -1 Deleted
  status: 1 | 2 | -1;
  text: string;
  attachments: NoteFile[];
  subComments: Comment[];
  createdBy: string;
  createdDate: string;
  creatorName: string;
  modifierName: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  editable: boolean;
}

export interface History {
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  id: string;
  bomId: string;
  bomType: string;
  batchId: string;
  bizId: string;
  eventType: string;
  bizName: string;
  creatorName: string;
  creatorRole: string;
  fieldName: null | string;
  oldValue: null | string;
  newValue: null | string;
}

export interface GDPRDetail {
  endDate: string;
  hgsSiteId: number;
  personalRequests: number;
  requests: number;
}

export interface GDPR {
  [key: string]: GDPRDetail;
}

export interface EmailDetail {
  id: string;
  subject: string;
  purpose: string;
  products: string[];
  status: string;
  creatorName: string;
  creatorRole: string;
  createdBy: string;
  createdDate: string;
  sendTime: string;
  platform: string;
  content?: string;
}

export interface OrgSearchOption {
  name: string;
  ciOrgId: string;
}

export type CallingSummaryData = {
  time: string;
  data: {
    totalCallings: number;
    totalMinutes: number;
    mau: number;
  };
};

export type CallingSummaryObject = {
  refreshedDate: string | null;
  summary: CallingSummaryData[];
};
export interface DeviceOverview {
  videoDeviceAmount: number;
  videoDeviceQty: number;
  videoDeviceMtrAmount: number;
  videoDeviceMtrQty: number;
  phoneAmount: number;
  phoneQty: number;
  peripheralsAmount: number;
  peripheralsQty: number;
  legacyQty: number;
  newGenQty: number;
  roomQty: number;
  deskQty: number;
  adoptionCloud: number;
  adoptionEdge: number;
  adoptionUnknown: number;
  featurePersonal: number;
  featureShared: number;
  featureOBTP: number;
  deviceTotal: number;
  hybridCalendar: boolean;
  modeNofMtrDevice: number;
  modePctMtrDevice: number;
  modeNofNonMtrDevice: number;
  modePctNonMtrDevice: number;
}

export interface DeviceList {
  cavBuId: number;
  cavBuName: string;
  cavId: number;
  cavName: string;
  guName: string;
  productFamily: string;
  bookingAmount: number;
  bookingQty: number;
  index?: number;
}

export interface JiraContent {
  comment: string;
  createdBy: string;
  createdDate: string;
  id: string;
  jiraId: string;
  jiraName: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  orgId: string;
  status: string;
  updated: string;
}
export interface WSMPData {
  orgId: string;
  jiraContents: JiraContent[];
  sites: Site[];
}
export interface JiraList {
  percentDesc: string;
  WSMPdata: WSMPData[];
}

export interface SelectActivity {
  id: string;
  activityName: string;
  activityStatus: string;
  accountKey: string;
  accountId: string;
}

export interface RevampCSMInfo {
  accountKey: string;
  name: string;
  cecId: string;
}
export interface SelectAccount {
  accountId: string;
  accountKey: string;
  accountName: string;
  csmInfo: RevampCSMInfo;
  videoDeviceCloudAmount3Y: number;
}

export interface SelectItem {
  code: string;
  value: string;
  description: string;
}

export interface AdvancedFilterMetricItem {
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  mechanism: string;
  mechanismType: string;
  metricId: string;
  metricName: string;
  metricType: string;
  modelName: any;
  optionType: any;
  optionValue: any;
  page: string;
}
